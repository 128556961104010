.popover__container {
  position: relative;
}

.popover__root {
  position: absolute;
  z-index: 1;
}

.popover__align-top {
  top: 0;
}

.popover__align-bottom {
  bottom: 0;
}

.popover__align-left {
  left: 0;
}

.popover__align-right {
  right: 0;
}

.popover__position-over {
  bottom: 100%;
  transform: translateY(-8px);
}

.popover__position-under {
  top: 100%;
  transform: translateY(8px);
}

.popover__position-left {
  right: 100%;
  transform: translateX(-8px);
}

.popover__position-right {
  left: 100%;
  transform: translateX(8px);
}
